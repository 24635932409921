import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule, inject, provideAppInitializer } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { LayoutWithoutSidenavComponent, SharedComponentsLayoutModule } from '@lc/shared-components-layout';
import {
  SharedComponentsAuthModule, OktaLoginComponent, OktaCallbackComponent, LogoutComponent,
} from '@lc/shared-components-auth';
import {
  CoreModule,
  StateModule,
  AuthGuard,
  AppService,
  initializeApp,
  StartupService,
  LaunchDarklyService,
  DataDogLogger,
  CloseDialogResolve,
} from '@lc/core';
import { UserIdleModule, UserIdleConfig } from 'angular-user-idle';
import { SharedComponentsUiModule } from '@lc/shared-components-ui';
import { InputsModule, UILayoutModule } from '@lc/ui';
import { AppComponent } from './app.component';
import '@abt-desk/rsc/lib/polyfills';
import '@abt-desk/rsc/lib/es2015';
import '@abt-desk/rsc/lib/es2015/footer';
import '@abt-desk/rsc/lib/es2015/header';
import '@abt-desk/rsc/lib/es2015/speed-dial';

if (location.hostname !== 'localhost' && location.hostname !== 'remotedev') {
  DataDogLogger.initialize('pub19b56e84527e84bc9c0e28fa98d7137a');
  DataDogLogger.initializeDDRealUserMonitoring('pub19b56e84527e84bc9c0e28fa98d7137a');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    CoreModule.forRoot(),
    StateModule,
    InputsModule,
    UILayoutModule,
    SharedComponentsAuthModule,
    SharedComponentsLayoutModule,
    SharedComponentsUiModule.forRoot(),
    RouterModule.forRoot([
      { path: '', loadChildren: () => import('@lc/suite-coord-app').then((m) => m.SuiteCoordAppModule) },
    ], {
      enableTracing: false,
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
    UserIdleModule,
  ],
  providers: [
    // User activity timeouts
    // Ping value is not used
    // Session timeout is pulled from AppSettings after load.  If not found default is 5 minutes
    { provide: UserIdleConfig, useFactory: () => ({ idle: AppService.get('sessionTimeout') * 60, timeout: 30, ping: 10000 }) },
    provideAppInitializer(() => {
      const initializerFn = (initializeApp)(inject(StartupService));
      return initializerFn();
    }),
    LaunchDarklyService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
